define('ember-cli-photoswipe/components/photo-swipe', ['exports', 'ember'], function (exports, _ember) {
  /* global PhotoSwipe */
  /* global PhotoSwipeUI_Default */

  'use strict';

  var run = _ember['default'].run;

  exports['default'] = _ember['default'].Component.extend({

    onInsert: _ember['default'].on('didInsertElement', function () {

      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        this.set('pswpEl', this.$('.pswp')[0]);
        this.set('pswpTheme', PhotoSwipeUI_Default);

        this._buildOptions();

        if (this.get('items')) {
          return this._initItemGallery();
        }

        /**
         * DEPRECATED
         *
         * Code exists for backward compatibility of block usage
         * up to ember-cli-photoswipe versions 1.0.1.
         */
        return this._calculateItems();
        /**
         * END DEPRECATED
         */
      });
    }),

    _buildOptions: function _buildOptions(getThumbBoundsFn) {
      var reqOpts = {
        history: false
      };

      if (_ember['default'].isPresent(getThumbBoundsFn)) {
        reqOpts.getThumbBoundsFn = getThumbBoundsFn;
      }

      var options = _ember['default'].merge(reqOpts, this.get('options') || {});
      this.set('options', options);
    },

    _initItemGallery: function _initItemGallery() {
      this.set('gallery', new PhotoSwipe(this.get('pswpEl'), this.get('pswpTheme'), this.get('items'), this.get('options')));
      this._reInitOnClose();
    },

    _reInitOnClose: function _reInitOnClose() {
      var component = this;
      this.get('gallery').listen('close', function () {
        run.next(function () {
          component._initItemGallery();
        });
      });
    },

    itemObserver: _ember['default'].observer('items', function () {
      var component = this;
      component._initItemGallery();
    }),

    /**
     * DEPRECATED
     *
     * Code exists for backward compatibility of block usage
     * up to ember-cli-photoswipe versions 1.0.1.
     */
    click: function click(evt) {

      if (this.get('items')) {
        return; // ignore - not using deprecated block form
      }

      var aElement = this.$(evt.target).parent();
      var index = this.$("a.photo-item").index(aElement);

      if (!aElement.is('a')) {
        return;
      }

      evt.preventDefault();

      // setup options, such as index for index
      this._buildOptions(this._getBounds.bind(this));
      this.set('options.index', index);

      var pSwipe = new PhotoSwipe(this.get('pswpEl'), this.get('pswpTheme'), this.get('calculatedItems'), this.get('options'));
      this.set('gallery', pSwipe);
      this.get('gallery').init();
    },
    /**
     * END DEPRECATED
     */

    _getBounds: function _getBounds(i) {
      var img = this.$('img').get(i),
          position = this.$(img).position(),
          width = this.$(img).width();
      return { x: position.left, y: position.top, w: width };
    },

    actions: {
      launchGallery: function launchGallery(item) {
        this._buildOptions(this._getBounds.bind(this));
        if (item !== undefined) {
          var index = this.get('items').indexOf(item);
          this.set('options.index', index);
        }
        var pSwipe = new PhotoSwipe(this.get('pswpEl'), this.get('pswpTheme'), this.get('items'), this.get('options'));
        this.set('gallery', pSwipe);
        this.get('gallery').init();
      }
    },

    /**
     * DEPRECATED
     *
     * Code exists for backward compatibility of block usage
     * up to ember-cli-photoswipe versions 1.0.1.
     */
    _calculateItems: function _calculateItems() {
      _ember['default'].deprecate("Using ember-cli-photoswipe without an items attribute is deprecated. " + "See https://github.com/poetic/ember-cli-photoswipe#usage", false, { id: 'ember-cli-photoswipe.didInsertElement', until: '1.13' });

      var items = this.$().find('a');
      var calculatedItems = _ember['default'].A(items).map(function (i, item) {
        return {
          src: _ember['default'].$(item).attr('href'),
          w: _ember['default'].$(item).data('width'),
          h: _ember['default'].$(item).data('height'),
          msrc: _ember['default'].$(item).children('img').attr('src'),
          title: _ember['default'].$(item).children('img').attr('alt')
        };
      });
      this.set('calculatedItems', calculatedItems);
    }
    /**
     * END DEPRECATED
     */

  });
});