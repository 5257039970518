define('ember-in-viewport/mixins/in-viewport', ['exports', 'ember-in-viewport/utils/can-use-dom', 'ember-in-viewport/utils/can-use-raf', 'ember-in-viewport/utils/can-use-intersection-observer', 'ember-in-viewport/utils/is-in-viewport', 'ember-in-viewport/utils/check-scroll-direction'], function (exports, _emberInViewportUtilsCanUseDom, _emberInViewportUtilsCanUseRaf, _emberInViewportUtilsCanUseIntersectionObserver, _emberInViewportUtilsIsInViewport, _emberInViewportUtilsCheckScrollDirection) {
  'use strict';

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var rAFIDS = {};
  var lastDirection = {};
  var lastPosition = {};

  exports['default'] = Ember.Mixin.create({
    /**
     * IntersectionObserverEntry
     *
     * @property intersectionObserver
     * @default null
     */
    intersectionObserver: null,
    viewportExited: Ember.computed.not('viewportEntered').readOnly(),

    init: function init() {
      this._super.apply(this, arguments);
      var options = Ember.assign({
        viewportUseRAF: (0, _emberInViewportUtilsCanUseRaf['default'])(),
        viewportUseIntersectionObserver: (0, _emberInViewportUtilsCanUseIntersectionObserver['default'])(),
        viewportEntered: false,
        viewportListeners: []
      }, this._buildOptions());

      Ember.setProperties(this, options);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (!_emberInViewportUtilsCanUseDom['default']) {
        return;
      }

      var viewportEnabled = Ember.get(this, 'viewportEnabled');
      if (viewportEnabled) {
        this._startListening();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this._unbindListeners();
      if (this.intersectionObserver) {
        this.intersectionObserver.unobserve(this.element);
      }
    },
    _buildOptions: function _buildOptions() {
      var defaultOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var owner = Ember.getOwner(this);

      if (owner) {
        return Ember.assign(defaultOptions, owner.lookup('config:in-viewport'));
      }
    },
    _startListening: function _startListening() {
      var _this = this;

      this._setInitialViewport();
      this._addObserverIfNotSpying();
      this._bindScrollDirectionListener(Ember.get(this, 'viewportScrollSensitivity'));

      if (!Ember.get(this, 'viewportUseRAF')) {
        Ember.get(this, 'viewportListeners').forEach(function (listener) {
          var context = listener.context,
              event = listener.event;

          context = Ember.get(_this, 'scrollableArea') || context;
          _this._bindListeners(context, event);
        });
      }
    },
    _addObserverIfNotSpying: function _addObserverIfNotSpying() {
      if (!Ember.get(this, 'viewportSpy')) {
        this.addObserver('viewportEntered', this, this._unbindIfEntered);
      }
    },
    _setViewportEntered: function _setViewportEntered() {
      var scrollableArea = Ember.get(this, 'scrollableArea') ? document.querySelector(Ember.get(this, 'scrollableArea')) : null;

      var element = Ember.get(this, 'element');

      if (!element) {
        return;
      }

      if (Ember.get(this, 'viewportUseIntersectionObserver')) {
        var _viewportTolerance = this.viewportTolerance,
            _viewportTolerance$to = _viewportTolerance.top,
            top = _viewportTolerance$to === undefined ? 0 : _viewportTolerance$to,
            _viewportTolerance$le = _viewportTolerance.left,
            left = _viewportTolerance$le === undefined ? 0 : _viewportTolerance$le,
            _viewportTolerance$bo = _viewportTolerance.bottom,
            bottom = _viewportTolerance$bo === undefined ? 0 : _viewportTolerance$bo,
            _viewportTolerance$ri = _viewportTolerance.right,
            right = _viewportTolerance$ri === undefined ? 0 : _viewportTolerance$ri;

        var options = {
          root: scrollableArea,
          rootMargin: top + 'px ' + right + 'px ' + bottom + 'px ' + left + 'px',
          threshold: Ember.get(this, 'intersectionThreshold')
        };

        this.intersectionObserver = new IntersectionObserver(Ember.run.bind(this, this._onIntersection), options);
        this.intersectionObserver.observe(element);
      } else {
        var $contextEl = scrollableArea ? Ember.$(scrollableArea) : Ember.$(window);
        var boundingClientRect = element.getBoundingClientRect();

        if (boundingClientRect) {
          this._triggerDidAccessViewport((0, _emberInViewportUtilsIsInViewport['default'])(boundingClientRect, $contextEl.innerHeight(), $contextEl.innerWidth(), Ember.get(this, 'viewportTolerance')));
          if (Ember.get(this, 'viewportUseRAF')) {
            rAFIDS[Ember.get(this, 'elementId')] = window.requestAnimationFrame(Ember.run.bind(this, this._setViewportEntered));
          }
        }
      }
    },

    /**
     * callback provided to IntersectionObserver
     *
     * @method _onIntersection
     * @param {Array} - entries
     */
    _onIntersection: function _onIntersection(entries) {
      var entry = entries[0];

      if (entry.isIntersecting) {
        Ember.set(this, 'viewportEntered', true);
        this.trigger('didEnterViewport');
      } else if (entry.intersectionRatio <= 0) {
        // exiting viewport
        Ember.set(this, 'viewportEntered', false);
        this.trigger('didExitViewport');
      }
    },
    _triggerDidScrollDirection: function _triggerDidScrollDirection() {
      var $contextEl = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var sensitivity = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      true && !$contextEl && Ember.assert('You must pass a valid context element to _triggerDidScrollDirection', $contextEl);
      true && !sensitivity && Ember.assert('sensitivity cannot be 0', sensitivity);

      var elementId = Ember.get(this, 'elementId');
      var lastDirectionForEl = lastDirection[elementId];
      var lastPositionForEl = lastPosition[elementId];
      var newPosition = {
        top: $contextEl.scrollTop(),
        left: $contextEl.scrollLeft()
      };

      var scrollDirection = (0, _emberInViewportUtilsCheckScrollDirection['default'])(lastPositionForEl, newPosition, sensitivity);
      var directionChanged = scrollDirection !== lastDirectionForEl;

      if (scrollDirection && directionChanged && Ember.get(this, 'viewportEntered')) {
        this.trigger('didScroll', scrollDirection);
        lastDirection[elementId] = scrollDirection;
      }

      lastPosition[elementId] = newPosition;
    },
    _triggerDidAccessViewport: function _triggerDidAccessViewport() {
      var hasEnteredViewport = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      var viewportEntered = Ember.get(this, 'viewportEntered');
      var didEnter = !viewportEntered && hasEnteredViewport;
      var didLeave = viewportEntered && !hasEnteredViewport;
      var triggeredEventName = '';

      if (didEnter) {
        triggeredEventName = 'didEnterViewport';
      }

      if (didLeave) {
        triggeredEventName = 'didExitViewport';
      }

      if (Ember.get(this, 'viewportSpy') || !viewportEntered) {
        Ember.set(this, 'viewportEntered', hasEnteredViewport);
      }

      this.trigger(triggeredEventName);
    },
    _unbindIfEntered: function _unbindIfEntered() {
      if (!Ember.get(this, 'viewportSpy') && Ember.get(this, 'viewportEntered')) {
        this._unbindListeners();
        this.removeObserver('viewportEntered', this, this._unbindIfEntered);
        Ember.set(this, 'viewportEntered', true);
      }
    },
    _setInitialViewport: function _setInitialViewport() {
      var _this2 = this;

      return Ember.run.scheduleOnce('afterRender', this, function () {
        _this2._setViewportEntered();
      });
    },
    _debouncedEventHandler: function _debouncedEventHandler(methodName) {
      var _this3 = this;

      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      true && !methodName && Ember.assert('You must pass a methodName to _debouncedEventHandler', methodName);
      true && !(Ember.typeOf(methodName) === 'string') && Ember.assert('methodName must be a string', Ember.typeOf(methodName) === 'string');

      Ember.run.debounce(this, function () {
        return _this3[methodName].apply(_this3, _toConsumableArray(args));
      }, Ember.get(this, 'viewportRefreshRate'));
    },
    _bindScrollDirectionListener: function _bindScrollDirectionListener() {
      var _this4 = this;

      var sensitivity = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      true && !sensitivity && Ember.assert('sensitivity cannot be 0', sensitivity);

      var $contextEl = Ember.get(this, 'scrollableArea') ? Ember.$(Ember.get(this, 'scrollableArea')) : Ember.$(window);

      $contextEl.on('scroll.directional#' + Ember.get(this, 'elementId'), function () {
        _this4._debouncedEventHandler('_triggerDidScrollDirection', $contextEl, sensitivity);
      });
    },
    _unbindScrollDirectionListener: function _unbindScrollDirectionListener() {
      var elementId = Ember.get(this, 'elementId');

      var context = Ember.get(this, 'scrollableArea') || window;

      Ember.$(context).off('scroll.directional#' + elementId);
      delete lastPosition[elementId];
      delete lastDirection[elementId];
    },
    _bindListeners: function _bindListeners() {
      var _this5 = this;

      var context = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var event = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      true && !context && Ember.assert('You must pass a valid context to _bindListeners', context);
      true && !event && Ember.assert('You must pass a valid event to _bindListeners', event);

      Ember.$(context).on(event + '.' + Ember.get(this, 'elementId'), function () {
        _this5._debouncedEventHandler('_setViewportEntered', context);
      });
    },
    _unbindListeners: function _unbindListeners() {
      var _this6 = this;

      var elementId = Ember.get(this, 'elementId');

      if (Ember.get(this, 'viewportUseRAF')) {
        Ember.run.next(this, function () {
          window.cancelAnimationFrame(rAFIDS[elementId]);
          delete rAFIDS[elementId];
        });
      }

      Ember.get(this, 'viewportListeners').forEach(function (listener) {
        var context = listener.context,
            event = listener.event;

        context = Ember.get(_this6, 'scrollableArea') ? Ember.get(_this6, 'scrollableArea') : context;
        Ember.$(context).off(event + '.' + elementId);
      });

      this._unbindScrollDirectionListener();
    }
  });
});